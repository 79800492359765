<template>
	<div v-html="apply">{{apply}}</div>
</template>
<script>
	export default {
		data() {
			return {
				apply: null
			}
		},
		created() {
			this.apply = this.$route.query.htmls;
			this.$nextTick(() => {
				document.forms[0].submit()
			})
		}
	}
</script>
